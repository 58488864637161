// third-party
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    stats: {},
    ownerStats: {},
    ownerBookings: [],
    ownerRooms: [],
};

const slice = createSlice({
    name: 'bookingsControl',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET bookings
        getStatsSuccess(state, action) {
            state.stats = action.payload;
        },

        getOwnerStatsSuccess(state, action) {
            state.ownerStats = action.payload;
        },

        getOwnerRoomsSuccess(state, action) {
            state.ownerRooms = action.payload;
        },

        getOwnerBookingsSuccess(state, action) {
            state.ownerBookings = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getStats(params) {
    return async () => {
        try {
            if(params.begin) params.begin = moment(params.begin).format('YYYY-MM-DD');
            if(params.end) params.end = moment(params.end).format('YYYY-MM-DD');
            const response = await axios.post(`/api/stats`, params);
            dispatch(slice.actions.getStatsSuccess(response.data.stats));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOwnerStats(params) {
    return async () => {
        try {
            if(params.begin) params.begin = moment(params.begin).format('YYYY-MM-DD');
            if(params.end) params.end = moment(params.end).format('YYYY-MM-DD');
            const response = await axios.post(`/api/stats/owner`, params);
            // Sort rooms by room.person?.title && room.title
            const rooms = response.data.rooms.sort((a, b) => {
                if(a.person?.title < b.person?.title) return -1;
                if(a.person?.title > b.person?.title) return 1;
                if(a.title < b.title) return -1;
                if(a.title > b.title) return 1;
                return 0;
            });
            dispatch(slice.actions.getOwnerRoomsSuccess(rooms));
            dispatch(slice.actions.getOwnerBookingsSuccess(response.data.bookings));
            dispatch(slice.actions.getOwnerStatsSuccess({
                monthly: response.data.monthlyStats,
                current: response.data.stats
            }));
            dispatch(slice.actions.getStatsSuccess(response.data.stats));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}