// third-party
import { createSlice } from '@reduxjs/toolkit';


// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    bookings: [],
    booking: null,
    comCategories: []
};

const slice = createSlice({
    name: 'bookingsControl',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET bookings
        getBookingsSuccess(state, action) {
            state.bookings = action.payload;
        },

        // GET booking
        getBookingSuccess(state, action) {
            state.booking = action.payload;
        },

        getComCategoriesSuccess(state, action) {
            state.comCategories = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function searchBookings(params) {
    return async () => {
        try {
            const response = await axios.post(`/api/booking/search`, params);
            dispatch(slice.actions.getBookingsSuccess(response.data.bookings));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBooking(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/${id}`);
            dispatch(slice.actions.getBookingSuccess(response.data.booking));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateBooking(id, params) {
    return async () => {
        try {
            const response = await axios.post(`/api/booking/${id}`, params);
            dispatch(slice.actions.getBookingSuccess(response.data.booking));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createBooking(params) {
    return async () => {
        try {
            const response = await axios.put(`/api/booking/new`, params);
            dispatch(slice.actions.getBookingSuccess(response.data.booking));
        } catch (error) {
            alert('An error occured while creating booking. Please try again later or contact administrator.');
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCommissionsCategories() {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/commissions/categories`);
            dispatch(slice.actions.getComCategoriesSuccess(response.data.categories));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteCommission(booking, id) {
    return async () => {
        try {
            const response = await axios.delete(`/api/booking/${booking}/commission/${id}`);
            dispatch(slice.actions.getBookingSuccess(response.data.booking));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addCommission(booking, params) {
    return async () => {
        try {
            const response = await axios.put(`/api/booking/${booking}/commission`, params);
            dispatch(slice.actions.getBookingSuccess(response.data.booking));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateCommission(booking, id, params) {
    return async () => {
        try {
            const response = await axios.post(`/api/booking/${booking}/commission/${id}`, params);
            dispatch(slice.actions.getBookingSuccess(response.data.booking));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}