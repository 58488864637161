// third-party
import { createSlice } from '@reduxjs/toolkit';


// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    currentDate: null,
    dispatched: null,
    rooms: [],
};

const slice = createSlice({
    name: 'resumeControl',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Planning
        getBookingsSuccess(state, action) {
            state.bookings = action.payload;
        },

        getRoomsSuccess(state, action) {
            state.rooms = action.payload;
        },

        getRoomsConfigsSuccess(state, action) {
            state.roomsConfigs = action.payload;
        },

        getRoomsPricesSuccess(state, action) {
            state.roomsPrices = action.payload;
        },

        getBookingSuccess(state, action) {
            state.booking = action.payload;
        },

        getChecksSuccess(state, action) {
            state.checks = action.payload;
        },

        getCalculatedPricesSuccess(state, action) {
            state.calculatedPrices = action.payload;
        },

        getAreasSuccess(state, action) {
            state.areas = action.payload;
        },

        getPricingRulesSuccess(state, action) {
            state.pricingRules = action.payload;
        },

        getDateSuccess(state, action) {
            state.currentDate = action.payload;
        },

        getInstructionsSuccess(state, action) {
            state.instructions = action.payload;
        },

        getDispatchedSuccess(state, action) {
            state.dispatched = new Date();
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetData(type) {
    return async () => {
        try {
            switch(type) {
                case 'rooms':
                    dispatch(slice.actions.getRoomsSuccess([]));
                    break;
                case 'bookings':
                    dispatch(slice.actions.getBookingsSuccess([]));
                    break;
                case 'checks':
                    dispatch(slice.actions.getChecksSuccess([]));
                    break;
                case 'calculatedPrices':
                    dispatch(slice.actions.getCalculatedPricesSuccess([]));
                    break;
                case 'roomsConfigs':
                    dispatch(slice.actions.getRoomsConfigsSuccess([]));
                    break;
                case 'roomsPrices':
                    dispatch(slice.actions.getRoomsPricesSuccess([]));
                    break;
                default:
                    dispatch(slice.actions.getBookingsSuccess([]));
                    dispatch(slice.actions.getRoomsSuccess([]));
                    dispatch(slice.actions.getRoomsConfigsSuccess([]));
                    dispatch(slice.actions.getRoomsPricesSuccess([]));
                    dispatch(slice.actions.getChecksSuccess([]));
                    dispatch(slice.actions.getCalculatedPricesSuccess([]));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRooms(search, showRemoved, sort, begin, end) {
    return async () => {
        try {
            const urlParams = new URLSearchParams();
            if(search) urlParams.append('search', search);
            if(showRemoved) urlParams.append('show_removed', showRemoved);
            if(sort) urlParams.append('sort', sort);
            if(begin) urlParams.append('begin', begin);
            if(end) urlParams.append('end', end);

            const response = await axios.get(`/api/rooms?${urlParams.toString()}`);
            dispatch(slice.actions.getRoomsSuccess(response.data.rooms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBookings(period, area, ignores = [], status, roomlist) {
    return async () => {
        const urlParams = new URLSearchParams();
        if(ignores.length > 0) urlParams.append('ignores', ignores.join(','));
        if(status) urlParams.append('status', status);
        if(roomlist) urlParams.append('roomlist', roomlist);

        try {
            const response = await axios.get(`/api/booking/period/bookings/${period}/${area ? area : 0}?${urlParams.toString()}`);
            dispatch(slice.actions.getBookingsSuccess(response.data.bookings));
            // dispatch(slice.actions.getRoomsSuccess(response.data.rooms));
            dispatch(slice.actions.getRoomsConfigsSuccess(response.data.roomsConfigs));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoomPrices(period, area) {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/period/roomprices/${period}/${area ? area : 0}`);
            dispatch(slice.actions.getRoomsPricesSuccess(response.data.roomsPrices));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setDate(date) {
    return async () => {
        try {
            dispatch(slice.actions.getDateSuccess(date));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getCalculatedPrices(period, area) {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/period/calculatedroomprices/${period}/${area ? area : 0}`);
            dispatch(slice.actions.getCalculatedPricesSuccess(response.data.calculatedPrices));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPricingRules(period, area) {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/pricingrules`);
            dispatch(slice.actions.getPricingRulesSuccess(response.data.rules));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getChecks(period) {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/period/checks/${period}`);
            dispatch(slice.actions.getChecksSuccess(response.data.checks));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAreas() {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/areas`);
            dispatch(slice.actions.getAreasSuccess(response.data.areas));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBooking(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/${id}`);
            dispatch(slice.actions.getBookingSuccess(response.data.booking));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoomInstructions(room) {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/${room}/instructions`);
            dispatch(slice.actions.getRoomsConfigsSuccess(response.data.roomsConfigs));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addRoomPowerUp(room, type, value, begin, end, area) {
    return async () => {
        try {
            const response = await axios.post(`/api/rooms/${room}/prices/powerup/update`, {type, value, begin, end, area});
            dispatch(slice.actions.getRoomsPricesSuccess(response.data.roomsPrices));
            // dispatch(slice.actions.getCalculatedPricesSuccess(response.data.calculatedPrices));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInstructions(room, lang) {
    return async () => {
        try {
            const response = await axios.get(`/api/rooms/${room}/instructions/${lang}`);
            dispatch(slice.actions.getInstructionsSuccess(response.data.instructions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setInstructions(instructions) {
    return async () => {
        try {
            dispatch(slice.actions.getInstructionsSuccess(instructions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function sendInstructions(bookings, text = '') {
    return async () => {
        try {

            const response = await axios.post(`/api/booking/instructions`, {bookings, text});
            if(response.data.success) {
                alert(`Les instructions sont en cours d'envoi. Patientez quelques instants pour voir les mises à jour dans la liste des réservations.`)
                return true;
            } else {
                dispatch(slice.actions.hasError(response.message));
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function sendPrecheck(booking, mode) {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/${mode}/${booking}/send`);
            if(response.data.success) {
                return true;
            } else {
                dispatch(slice.actions.hasError(response.message));
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    }
}