/**
 * axios setup to use mock service
 */

import axios from 'axios';

let options = {}
// console.log(document.location.hostname)
if(document.location.hostname === "localhost") {
    options.baseURL = process.env.REACT_APP_API_URL;
}

const axiosServices = axios.create(options);

axiosServices.interceptors.request.use(function (config) {
    // Do something before request is sent
    // console.log("Call", config.url)
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
