// third-party
import { createSlice } from '@reduxjs/toolkit';


// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    bookings: {}
};

const slice = createSlice({
    name: 'accountsControl',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Planning
        getAccountsSuccess(state, action) {
            state.accounts = action.payload;
        },

        getAccountSuccess(state, action) {
            state.account = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAccounts() {
    return async () => {
        try {
            const response = await axios.get(`/api/account/list`);
            dispatch(slice.actions.getAccountsSuccess(response.data.accounts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAccount(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/account/${id}`);
            dispatch(slice.actions.getAccountSuccess(response.data.account));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
