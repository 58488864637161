// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    bookings: {}
};

const slice = createSlice({
    name: 'bookingControl',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getBookingsSuccess(state, action) {
            state.bookings = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBookingList(year, month) {
    return async () => {
        try {
            const response = await axios.get(`/api/accounting/compare/${year}/${month}`);
            dispatch(slice.actions.getBookingsSuccess(response.data.reservations));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateBookingAmount(bookingId, amount, comment) {
    return new Promise((resolve, reject) => {
        axios
            .post(`/api/accounting/update/${bookingId}`, { amount, comment })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
