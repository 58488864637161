// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import customerReducer from './slices/customer';
import contactReducer from './slices/contact';
import controlReducer from './slices/control';
import accountsReducer from './slices/accounts';
import noticesReducer from './slices/notices';
import resumeControl from './slices/resume';
import roomAccessReducer from './slices/roomAccess';
import cleaningReducer from './slices/cleaning';
import productReducer from './slices/product';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import adminReducer from './slices/adminUsers';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';
import roomsReducer from './slices/rooms';
import bookingReducer from './slices/bookings';
import statsReducer from './slices/stats';
import notificationsReducer from './slices/notifications';
import accountancyReducer from './slices/accountancy';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    accounts: accountsReducer,
    notices: noticesReducer,
    contact: contactReducer,
    control: controlReducer,
    resume: resumeControl,
    cleaning: cleaningReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    menu: menuReducer,
    admins: adminReducer,
    roomAccess: roomAccessReducer,
    rooms: roomsReducer,
    bookings: bookingReducer,
    stats: statsReducer,
    notifications: notificationsReducer,
    accountancy: accountancyReducer
});

export default reducer;
