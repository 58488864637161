// import widget from './widget';
import application from './application';
import administration from './administration';
import rooms from './rooms';
import accounting from './accounting';
import settings from './settings';
import home from './home';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        // widget, 
        home,
        application, 
        administration,
        rooms,
        accounting,
        settings
        // forms, 
        // elements, 
        // pages, 
        // utilities, 
        // support, 
        // other
    ]
};

export default menuItems;
