import { FormattedMessage } from 'react-intl';

import { IconHomeSignal } from '@tabler/icons';

const home = {
    id: 'home',
    title: <FormattedMessage id="home" />,
    type: 'group',
    icon: IconHomeSignal,
    url: '/dashboard/analytics',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            icon: IconHomeSignal,
            url: '/dashboard/analytics'
        },
        // {
        //     id: 'notifications',
        //     title: <FormattedMessage id="Notifications" />,
        //     type: 'item',
        //     icon: IconBellRinging,
        //     url: '/dashboard/notifications'
        // }
    ]
}

export default home;