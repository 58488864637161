// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    bookings: {}
};

const slice = createSlice({
    name: 'cleaningControl',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET Planning
        getPlanningSuccess(state, action) {
            state.planning = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPlanning(date) {
    return async () => {
        try {
            const response = await axios.get(`/api/booking/management/planning${date && date !== '' ? '/' + date : ''}`);
            dispatch(slice.actions.getPlanningSuccess(response.data.roomListBuckets));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
