// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { 
    IconApps, 
    IconUserCheck, 
    IconBasket, 
    IconMessages, 
    IconLayoutKanban, 
    IconMail, 
    IconCalendar, 
    IconNfc, 
    IconSpray, 
    IconCalendarEvent, 
    IconBrandAirbnb, 
    IconLayoutList, 
    IconSearch,
    IconBrandTrello
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBrandTrello
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'resumeContainer',
            title: <FormattedMessage id="bookings" />,
            type: 'collapse',
            icon: IconCalendarEvent,
            children: [
                {
                    id: 'planning',
                    title: <FormattedMessage id="planning" />,
                    type: 'item',
                    icon: IconCalendarEvent,
                    url: '/dashboard/planning',
                },
                {
                    id: 'resume',
                    title: <FormattedMessage id="resume" />,
                    type: 'item',
                    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
                    icon: IconLayoutList,
                    url: '/dashboard/resume',
                },
                {
                    id: 'search',
                    title: <FormattedMessage id="search" />,
                    type: 'item',
                    roles: ['ROLE_ADMIN', 'ROLE_OWNER'],
                    icon: IconSearch,
                    url: '/app/bookings/search',
                    dynamic: '/app/bookings/[a-zA-Z0-9]+',
                },
            ]
        },
        {
            id: 'chat',
            title: <FormattedMessage id="chat" />,
            type: 'item',
            roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
            icon: icons.IconMessages,
            url: '/app/chat',
            dynamic: '/app/chat/[a-zA-Z0-9]+',
        },
        {
            id: 'tasks',
            title: <FormattedMessage id="tasks" />,
            type: 'item',
            roles: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_OWNER'],
            icon: icons.IconBrandTrello,
            url: '/app/kanban/board'
        },
        {
            id: 'bnb-management',
            title: <FormattedMessage id="bnb-management" />,
            icon: icons.IconCalendar,
            type: 'collapse',
            roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
            children: [
                {
                    id: 'cleaning',
                    icon: IconSpray,
                    title: <FormattedMessage id="cleanings" />,
                    type: 'item',
                    url: '/app/cleaning/planning',
                    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
                },
                {
                    id: 'airbnb-accounts',
                    icon: IconBrandAirbnb,
                    title: <FormattedMessage id="airbnb-accounts" />,
                    type: 'item',
                    url: '/app/airbnb/accounts',
                    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
                }
                ,
                {
                    id: 'airbnb-notices',
                    icon: IconBrandAirbnb,
                    title: <FormattedMessage id="airbnb-notices" />,
                    type: 'item',
                    url: '/app/airbnb/notices',
                    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
                }
            ]
        },
        // {
        //     id: 'contact',
        //     title: <FormattedMessage id="contact" />,
        //     type: 'collapse',
        //     icon: icons.IconNfc,
        //     children: [
        //         {
        //             id: 'c-card',
        //             title: <FormattedMessage id="cards" />,
        //             type: 'item',
        //             url: '/app/contact/c-card',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'c-list',
        //             title: <FormattedMessage id="list" />,
        //             type: 'item',
        //             url: '/app/contact/c-list',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'e-commerce',
        //     title: <FormattedMessage id="e-commerce" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'products',
        //             title: <FormattedMessage id="products" />,
        //             type: 'item',
        //             url: '/e-commerce/products'
        //         },
        //         {
        //             id: 'product-details',
        //             title: <FormattedMessage id="product-details" />,
        //             type: 'item',
        //             url: '/e-commerce/product-details/1',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-list',
        //             title: <FormattedMessage id="product-list" />,
        //             type: 'item',
        //             url: '/e-commerce/product-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'checkout',
        //             title: <FormattedMessage id="checkout" />,
        //             type: 'item',
        //             url: '/e-commerce/checkout'
        //         }
        //     ]
        // }
        // {
        //     id: 'users',
        //     title: <FormattedMessage id="users" />,
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         // {
        //         //     id: 'posts',
        //         //     title: <FormattedMessage id="social-profile" />,
        //         //     type: 'item',
        //         //     url: '/user/social-profile/posts'
        //         // },
        //         // {
        //         //     id: 'account-profile',
        //         //     title: <FormattedMessage id="account-profile" />,
        //         //     type: 'collapse',
        //         //     children: [
        //         //         {
        //         //             id: 'profile1',
        //         //             title: (
        //         //                 <>
        //         //                     <FormattedMessage id="profile" /> 01
        //         //                 </>
        //         //             ),
        //         //             type: 'item',
        //         //             url: '/user/account-profile/profile1'
        //         //         },
        //         //         {
        //         //             id: 'profile2',
        //         //             title: (
        //         //                 <>
        //         //                     <FormattedMessage id="profile" /> 02
        //         //                 </>
        //         //             ),
        //         //             type: 'item',
        //         //             url: '/user/account-profile/profile2'
        //         //         },
        //         //         {
        //         //             id: 'profile3',
        //         //             title: (
        //         //                 <>
        //         //                     <FormattedMessage id="profile" /> 03
        //         //                 </>
        //         //             ),
        //         //             type: 'item',
        //         //             url: '/user/account-profile/profile3'
        //         //         }
        //         //     ]
        //         // },
        //         {
        //             id: 'user-card',
        //             title: <FormattedMessage id="cards" />,
        //             type: 'collapse',
        //             children: [
        //                 {
        //                     id: 'card1',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 01
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/card/card1'
        //                 },
        //                 {
        //                     id: 'card2',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 02
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/card/card2'
        //                 },
        //                 {
        //                     id: 'card3',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 03
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/card/card3'
        //                 }
        //             ]
        //         },
        //         {
        //             id: 'user-list',
        //             title: <FormattedMessage id="list" />,
        //             type: 'collapse',
        //             children: [
        //                 {
        //                     id: 'list1',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 01
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/list/list1'
        //                 },
        //                 {
        //                     id: 'list2',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="style" /> 02
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '/user/list/list2'
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // {
        //     id: 'customer',
        //     title: <FormattedMessage id="customer" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'customer-list',
        //             title: <FormattedMessage id="customer-list" />,
        //             type: 'item',
        //             url: '/customer/customer-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'order-list',
        //             title: <FormattedMessage id="order-list" />,
        //             type: 'item',
        //             url: '/customer/order-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'create-invoice',
        //             title: <FormattedMessage id="create-invoice" />,
        //             type: 'item',
        //             url: '/customer/create-invoice',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'order-details',
        //             title: <FormattedMessage id="order-details" />,
        //             type: 'item',
        //             url: '/customer/order-details'
        //         },
        //         {
        //             id: 'product',
        //             title: <FormattedMessage id="product" />,
        //             type: 'item',
        //             url: '/customer/product',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-review',
        //             title: <FormattedMessage id="product-review" />,
        //             type: 'item',
        //             url: '/customer/product-review',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
    ]
};

export default application;
