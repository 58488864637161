import { FormattedMessage } from 'react-intl';

import { IconTool } from '@tabler/icons';
import { IconBrandAirbnb, IconBuildingSkyscraper } from '@tabler/icons';


const administration = {
    id: 'rooms',
    title: <FormattedMessage id="rooms-management" />,
    type: 'group',
    icon: IconTool,
    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
    children: [
        {
            id: 'rooms',
            title: <FormattedMessage id="rooms-management" />,
            type: 'item',
            url: '/app/rooms',
            roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
            icon: IconBuildingSkyscraper,
        },
        {
            id: 'listings',
            title: <FormattedMessage id="listings" />,
            type: 'item',
            url: '/app/listings',
            roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
            icon: IconBrandAirbnb,
        }
    ]
}

export default administration;