// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    notifications: [],
    nbNotifications: 0
};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getNotificationsSuccess(state, action) {
            state.notifications = action.payload;
        },
        getCountNotificationsSuccess(state, action) {
            state.nbNotifications = action.payload;
        }
    }
});

export default slice.reducer;

export function getNotifications() {
    return async () => {
        try {
            const response = await axios.get('/api/notifications');
            dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
            dispatch(slice.actions.getCountNotificationsSuccess(response.data.count));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function markAsRead(notification) {
    return async () => {
        try {
            const response = await axios.get(`/api/notifications/mark-as-read/${notification}`);
            dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
            dispatch(slice.actions.getCountNotificationsSuccess(response.data.count));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function markAllAsRead() {
    return async () => {
        try {
            const response = await axios.get('/api/notifications/mark-as-read');
            dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
            dispatch(slice.actions.getCountNotificationsSuccess(response.data.count));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}