import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import menuItems from 'menu-items';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';
import { DashboardMenu } from 'menu-items/dashboard';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const handlerMenuItem = (setNavItems, layout, user, getDash, matchDownMd) => {
    let tmpMenuItem = Object.assign({}, menuItems);

    const isFound = tmpMenuItem.items.some((element) => {
        if (element.id === 'dashboard') {
            return true;
        }
        return false;
    });


    if (getDash?.id !== undefined && !isFound) {
        tmpMenuItem.items.splice(0, 0, getDash);
    }

    // Remove entries from menu that are not allowed for user.role
    if (user && user?.role) {
        tmpMenuItem.items = tmpMenuItem.items.filter((item) => {
            if (item.roles) {
                if (item.roles.includes(user.role)) {
                    return true;
                }
                return false;
            }
            return true;
        });

        // Do the same for children
        tmpMenuItem.items.forEach((item) => {
            if (item.children) {
                item.children = item.children.filter((child) => {
                    if (child.roles) {
                        if (child.roles.includes(user.role)) {
                            return true;
                        }
                        return false;
                    }
                    return true;
                });
            }
        });

        // Do the same for subChildren
        tmpMenuItem.items.forEach((item) => {
            if (item.children) {
                item.children.forEach((child) => {
                    if (child.children) {
                        child.children = child.children.filter((subChild) => {
                            if (subChild.roles) {
                                if (subChild.roles.includes(user.role)) {
                                    return true;
                                }
                                return false;
                            }
                            return true;
                        });
                    }
                });
            }
        });
    }

    // // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = tmpMenuItem.items.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < tmpMenuItem.items.length) {
        lastItemId = tmpMenuItem.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = tmpMenuItem.items.slice(lastItem - 1, tmpMenuItem.items.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }

    setNavItems(tmpMenuItem.items.slice(0, lastItemIndex + 1).map((item) => {
        // return "test"
        if(item) {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
                default:
                    console.log('Here ?', item)
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        } else {
            return null;
        }
    }));
};

const MenuList = () => {
    const theme = useTheme();
    const { layout } = useConfig();
    const { user } = useAuth();
    const [navItems, setNavItems] = useState(null);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const getDash = DashboardMenu();

    useEffect(() => {
        handlerMenuItem(setNavItems, layout, user, getDash, matchDownMd);
        // eslint-disable-next-line
    }, [user]);

    return <>{navItems}</>;
};

export default memo(MenuList);
