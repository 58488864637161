import { useEffect, useState } from 'react';

// routing
import Routes from 'routes';
import moment from 'moment';
import numeral from 'numeral';
import 'moment/locale/fr';
import 'numeral/locales';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';
import { CookiesProvider } from 'react-cookie';

import ThemeCustomization from 'themes';
// import { getDashboard } from 'store/slices/menu';

// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

moment.locale('fr');
numeral.locale('fr');

const App = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // dispatch(getDashboard()).then(() => {
            setLoading(true);
        // });
    }, []);

    if (!loading) return <Loader />;

    return (
        <ThemeCustomization>
            <RTLLayout>
                <CookiesProvider>
                    <Locales>
                        <NavigationScroll>
                            <AuthProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </AuthProvider>
                        </NavigationScroll>
                    </Locales>
                </CookiesProvider>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
