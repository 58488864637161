// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    accesses: [],
    owners: [],
    rooms: []
};

const slice = createSlice({
    name: 'roomAccessReducer',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getRoomAccessSuccess(state, action) {
            state.accesses = action.payload;
        },
        getRoomOwnersSuccess(state, action) {
            state.owners = action.payload;
        },
        getRoomsSuccess(state, action) {
            state.rooms = action.payload;
        }
    }
});

export default slice.reducer;

export function getRoomAccess(params) {
    return async () => {
        try {
            let url = '/api/rooms/accesses';
            const response = await axios.get(url);
            dispatch(slice.actions.getRoomAccessSuccess(response.data.accesses));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function searchOwners(search) {
    return async () => {
        try {
            let url = `/api/users/ROLE_OWNER?search=${search}`;
            const response = await axios.get(url);
            dispatch(slice.actions.getRoomOwnersSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function searchRooms(search) {
    return async () => {
        try {
            let url = `/api/rooms/search?q=${search}`;
            const response = await axios.get(url);
            dispatch(slice.actions.getRoomsSuccess(response.data.rooms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function manageRoomAccesses(user, rooms) {
    return async () => {
        try {
            let url = `/api/rooms/accesses`;
            const response = await axios.post(url, { user, rooms });
            dispatch(slice.actions.getRoomAccessSuccess(response.data.accesses));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}