import { FormattedMessage } from 'react-intl';

import { IconTool } from '@tabler/icons';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import { FaTasks } from "react-icons/fa";

const administration = {
    id: 'administration',
    title: <FormattedMessage id="administration" />,
    type: 'group',
    icon: IconTool,
    roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
    children: [
        {
            id: 'admins',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/user/list/users',
            roles: ['ROLE_ADMIN'],
            icon: AdminPanelSettingsOutlinedIcon,
        },
        {
            id: 'roomAccess',
            title: <FormattedMessage id="roomAccess" />,
            type: 'item',
            url: '/user/list/room-access',
            roles: ['ROLE_ADMIN'],
            icon: RoomPreferencesOutlinedIcon,
        },
        {
            id: 'cases',
            title: <FormattedMessage id="cases" />,
            type: 'item',
            url: '/app/cases',
            roles: ['ROLE_ADMIN', 'ROLE_AGENT'],
            icon: FaTasks,
        }
    ]
}

export default administration;